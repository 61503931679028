.privacy-question
{
	padding-left: 16px;
	padding-right: 20px;
	padding-bottom: 20px;
	.list-group
	{
		.list-group-item
		{
			padding: 10px 0px;
			border-left: transparent;
			border-right: transparent;
			border-radius: 0px;
			.form-group
			{
				width: 100%;
				label
				{
					font-family: @primary-font;
					font-size: @primary-font-size;
					color: #7F8FA4;
					font-weight: 400;
					text-transform: none;
					margin-bottom: 0px;
					padding-top: 8px;
				}
				.form-control
				{
					border: 1px solid #CED0DA;
					border-radius: 4px;
					float: right;
					width: 130px;
				}
			}
			
		}
		.list-group-item:first-child
		{
			border-top: transparent;
			padding-top: 16px;
		}
	}
	.btn-success
	{
		padding: 7px 12.5px;
	}
}
.footer-description
{
	.socialite-terms
	{
		padding-bottom: 10px;
		font-size: @primary-font-size;
		font-family: @primary-font;
		font-weight: 400;
		@media (max-width:440px)
		{
			font-size: 11px;
		}
		a
		{
			font-family: @primary-font;
			font-size: @primary-font-size;
			color: #249AF2;
			text-transform: capitalize;
			font-weight: 400;
			vertical-align: middle;
			@media (max-width:440px)
			{
				font-size: 11px;
			}
		}
	}
	.socialite-terms:nth-child(1)
	{
		border-top:1px solid #E6EAEE;
		padding-top: 24px;
	}
}
.list-group.socialite-group
{
	margin-bottom: 20px;
	.list-group-item
	{	
		padding: 6px 15px !important;
		.badge
		{
			margin-top: 9px;
		}
		&:hover
		{
			.socialite-icon
			{
				background: #269BF3;
				i
				{
					color: #FFFFFF;
				}
			}
		}
	}
	.list-icon.socialite-icon
	{
		background: #E8EAF1;
		i
		{
			color: #8D909F;
		}
		&:hover
		{
			background: #269BF3;
			i
			{
				color: #ffffff;
			}
		}
	}
	.list-icon.active
	{
		background: #269BF3;
		i
		{
			color: #ffffff;
		}
	}
}
//general-settings
.panel-heading.panel-settings
{
	padding-top: 24px;
	padding-left: 27px;
	.panel-title
	{
		font-family: @primary-font;
		font-size: 24px;
		font-weight: 400;
	}
}
.socialite-form
{
	padding-left: 32px;
	padding-right: 32px;
	padding-bottom: 20px;
	padding-top: 29px;
	.form-control
	{
		padding-right: 35px !important;
	}
	.help-block
	{
		padding: 10px 0px 0px 0px;
	}
	.avatar-details
	{
		
		.avatar-pic
		{
			display: inline-block;
			padding-left: 17px;
			.avatar-name
			{
				font-size: @primary-font-size;
				color: @primary-content-color;
				text-transform: capitalize;
				font-weight: 600;
				margin: 0px;
			}
			.avatar-size
			{
				font-size: 12px;
				color: #7F8FA4;
				font-weight: 400;
				margin: 0px;
			}
		}
	}
	.form-group
	{
		.input-group.gender-select
		{
			width: 100%;
			.form-control
			{
				font-family: @primary-font;
				font-size: @primary-font-size;
				color: @primary-content-color;
				border-radius: 4px;
			}
		}
		.input-group.datepicker
		{
			padding: 0px;
			.calendar-addon
			{
				color: #C5D0DE;
				font-size: 16px;
			}
			.form-control
			{
				background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
				border-right: none;
				border-left: none;	
			}
		}
	}
}
.socialite-security
{
	padding-left: 32px;
	padding-right: 32px;
	padding-top: 28px;
	.btn-save-change
	{
		padding-top: 22px;
	}
}
//affiliates
.affiliate-settings
{
	.affiliate-link
	{
		text-align: center;
		padding-bottom: 20px;
		.link-heading
		{
			font-family: @source-sans;
			font-size: @primary-font;
			color: @primary-content-color;
			font-weight: 400;
			margin: 0px;
			padding-bottom: 7px;
		}
		a
		{
			font-family: @source-sans;
			font-size: @primary-font;
			color: #789A6A;
			font-weight: 400;
		}
		.affiliate-buttons
		{
			padding-top: 8px;
			.btn-default
			{
				padding: 6px 15px;
				color: @primary-content-color;
				margin-right: 17px;
				i
				{
					color: #3B5998;
					font-size: 20px;
					padding-right: 9px;
				}
			}
			.btn-default.btn-twitter
			{
				padding: 6px 25px;
				i
				{
					color: #5EA9DD;
					font-size: 20px;
				}
			}
		}
	}
	.affliate-followers
	{
		.list-group-item
		{
			border-left: none;
			border-right: none;
			border-radius: 0px;
			padding: 15px 0px;
			.connect-link
			{
				a
				{
					font-family: @primary-font;
					font-size: @primary-font-size;
					color: @primary-content-color;
					font-weight: 400;
					img
					{
						width: 40px;
						height: 40px;
						border-radius: 0px;
					}
					.name
					{
						padding-left: 20px;
					}
				}
			}
			.connect-mail
			{
				.btn-default
				{
					padding: 7px 17px;
					i
					{
						color: #1B92EC;
						font-size: 14px;
						padding-right: 9px;
					}
				}
			}
		}
		
	}
}
//connected-services
.startpeep-services
{
	padding-left: 13px;
	padding-right: 13px;
	.list-group-item
	{
		border-left: none;
		border-right: none;
		border-radius: 0px;
		padding: 15px 0px;
		.connect-list
		{
			.connect-link
			{
				a
				{
					font-family: @primary-font;
					font-size: @primary-font-size;
					color: @primary-content-color;
					font-weight: 400;
					text-decoration: none;
					letter-spacing: 0.5px;
					text-transform: capitalize;
					img
					{
						width: 16px;
						height: 16px;
						margin-right: 10px;
					}
				}
			}
			.connect-mail
			{
				a
				{
					font-family: @primary-font;
					font-size: @primary-font-size;
					color: #7F8FA4;
					font-weight: 400;
					text-decoration: none;
				}
				.btn-cross
				{
					padding: 0px;
					width: 18px;
					height: 18px;
					border-radius: 50%;
					background-image: linear-gradient(-1deg, #F3F5F6 0%, #FFFFFF 100%);
					border: 1px solid #D6D7E0;
					line-height: 13px;
					box-shadow: none;
					margin-left: 9px;
					vertical-align: text-bottom;
					i
					{
						color: #B2B3BF;
						font-size: 11px;
					}
				}
				.btn-success
				{
					color: #FFFFFF;
				}
			}
		}
	}
	li:nth-child(1)
	{
		border-top: none;
	}
	li:nth-child(2)
	{
		padding: 7px 0px;
		.connect-link
		{
			margin-top: 7px;
		}
	}
	li:nth-child(5)
	{
		border-bottom: none;
	}
	li:nth-child(4)
	{
		padding: 7px 0px;
		.connect-link
		{
			margin-top: 7px;
		}
	}
}
//delete account
.accout-deactivate
{
	position: relative;
	text-align: center;
	i
	{
		color: rgba(231,104,118,0.07);
		font-size: 150px;
	}
	.delete-text
	{
		position: absolute;
		bottom: 50px;
		left: 0px;
		right: 0px;
		font-family: @primary-font;
		font-size: 16px;
		color: @primary-content-color;
		font-weight: 400;
	}
}
.delete-btn
{
	text-align: center;
	padding-bottom: 30px;
	padding-top: 8px;
	.btn-danger
	{
		background: #E76877;
		border: 1px solid #E76877;
	}
}
//page-likes
.list-group.page-likes
{
	padding-left: 13px;
	padding-right: 13px;
	.list-group-item
	{
		border-left: none;
		border-right: none;
		border-radius: 0px;
		padding: 15px 0px;
		.connect-link
		{
			a
			{
				font-family: @primary-font;
				font-size: @primary-font-size;
				color: @primary-content-color;
				font-weight: 400;
				text-decoration: none;
				letter-spacing: 0.5px;
				text-transform: capitalize;
				img
				{
					width: 40px;
					height: 40px;
					margin-right: 20px;
				}
			}
		}
		.pull-right
		{
			padding-top: 5px;
			.btn-primary
			{
				color: #FFFFFF;
				i
				{
					color: #FFFFFF;
					padding-right: 12px;
				}
			}
		}
	}
	li:nth-child(1)
	{
		border-top: 0px;
	}
	li:nth-child(5)
	{
		border-bottom: 0px;
	}
}
//email notifications
.privacy-question
{
	.calendar-switch
	{
		padding-right: 0px;
		margin-right: 0px;
		padding-top: 5px;
	}
}
//pages-groups
.list-group
{
	li:last-child
	{
		border-bottom: none;
	}
}
//user-settings
.mini-profile
{
	.widget-bg
	{
		img
		{
			border-radius: 4px 4px 0px 0px;
		}
	}
	.avatar-name
	{
		margin-top: -8px !important;
	}
	.avatar-mail
	{
		margin-top: -8px !important;
		a
		{
			font-family: @primary-font;
			color: #7F8FA4;
			font-weight: 400;
			font-size: 14px;
			text-decoration: none;
		}
	}
	.activity-list
	{
		margin-top: 17px;
		.activity-count
		{
			text-align: center;
		}
	}
}
//footer
.footer-description
{
	padding-top: 100px;
	.socialite-terms
	{
		a
		{
			color: @primary-content-color;
			text-decoration: none;
			&:hover
			{
				color: #1991EB;
			}
		}
	}
	.socialite-languages
	{
		span
		{
			color: @primary-content-color;
		}
		a
		{
			color: @primary-content-color;
			text-decoration: none;
			&:hover
			{
				color: #1991EB;
			}
		}
	}
}
//general settings
.socialite-form
{
	.radio
	{
		label
		{
			input
			{
				margin-top: 0px;
			}
		}
		p
		{
			text-transform: none;
		}
	}
}
//widget-left-panel
.widget-left-panel
{
	.active
	{
		.menu-btn
		{
			i
			{
				color: #FFFFFF !important;
			}
		}
	}
}
//general website settings
.socialite-form
{
	.form-group
	{
		.youtube-input-group
		{
			.fb-btn
			{
				background: #cc181e;
				border-color: #cc181e;
				i
				{
					color: #FFFFFF;
				}
			}
		}
	}
	.privacy-question
	{
		padding-left: 0px;
		padding-right: 0px;
		padding-bottom: 0px;
		.list-group-item
		{
			.form-group
			{
				margin-bottom: 0px;
			}
		}
	}
}
//manage users
.timeline
{
	.manage-table
	{
		td
		{
			font-weight: 300;
			li
			{
				padding-left: 0px;
				padding-right: 0px;
				a
				{
					font-weight: 300;
					font-family: @primary-font;
					font-size: 14px;
					padding-left: 7px;
					
				}
			}
		}
		td:nth-child(3)
		{
			padding-left: 0px;
		}
		td:nth-child(5)
		{
			width: 100px;
		}
	}
}
//theme-settings
.theme-block
{
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	border-bottom: 1px solid red;
	.theme-picture
	{
		.theme-img
		{
			float: left;
			img
			{
				width: 50px;
				height: 50px;
				border-radius: 4px;
			}
		}
		.theme-header
		{
			float: left;
			.theme-heading
			{
				font-family: @primary-font;
				font-size: @primary-font-size;
				color: @primary-content-color;
				font-weight: 400;
				margin-bottom: 0px;
			}
			h3:nth-child(1)
			{
				margin-top: -8px;
			}
			h3:nth-child(2)
			{
				margin-top: -10px;
			}
			h3:nth-child(3)
			{
				margin-top: -8px;
			}
		}
	}
}
.theme-passage
{
	padding-left: 27px;
	padding-top: 17px;
	p
	{
		font-family: @primary-font;
		font-size: @primary-font-size;
		color: @primary-content-color;
		font-weight: 400;
		margin-bottom: 0px;
	}
}
//news-letter
.form-horizontal
{
	padding-top: 29px;
	.form-group
	{
		margin-left: 12px;
		margin-right: 12px;
	}
	.form-group:nth-child(1)
	{
		border-bottom: 1px solid #E6EAEE;;
		padding-bottom: 37px;
		margin-bottom: 21px;
	}
	.btn-success
	{
		padding: 6.5px 28px;
	}
}
.page-header.news-heading
{
	margin: 9px 0 17px;
	padding-left: 35px;
}
//announcements
.announcements
{
	.datepicker	
	{
		padding-left: 19px !important;
		padding-right: 19px !important;
		.form-control
		{
			background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
		    border-left: 0px;
		    border-right: 0px;
		}
	}
	.form-group:nth-child(1)
	{
		padding-bottom: 0px;
		border-bottom: none;
	}
	.form-group
	{
		label
		{
			padding-left: 0px;
			padding-right: 0px;
		}
		.upload-form
		{
			input
			{
				margin-top: 3px;
				&:focus
				{
					outline: none;
				}
			}
		}
	}
}
//dash-board
.table.annual-statistics
{
	tr
	{
		td:first-child
		{
			width: 200px;
			font-size: 15px;
		}
		td
		{
			width: 150px;
			border-top: transparent;
			padding-bottom: 18px;
			padding-top: 18px;
			.widget-sales
			{
				background-color: #f5f5f5;
				border-radius: 4px;
				padding-top: 0px;
				padding-bottom: 0px;
				border: 1px solid @panel-border-color;
				.no-of-sales
				{
					padding-bottom: 0px;
					padding-top: 6px;
					font-size: 25px;
				}
				.sales
				{
					padding-bottom: 10px;
					a
					{
						text-transform: uppercase;
						font-size: 12px;
					}
				}
			}
		}
	}
	tr:last-child
	{
		td
		{
			border-bottom: 0px;
		}
	}
}
@media (max-width:700px)
{
	.table.annual-statistics
	{
		tr
		{
			td
			{
				width: 0px;
				.widget-sales
				{
					padding-left: 20px;
					padding-right: 20px;
				}
			}
		}
	}
}
//edit user page
.edit-form
{
	.form-group
	{
		padding-left: 29px;
		padding-right: 29px;
	}
	.pull-right
	{
		padding-right: 29px;
	}
}
//manage reports
@media (max-width:620px)
{
	.heading-list
	{
		li
		{
			a
			{
				font-size: 15px;
				padding: 33px 5px 8px 0px;
				span
				{
					font-size: 10px;
				}
			}
		}
	}
}
//global declaration
.avatar-body
{
	width: 78px;
	height: 70px;
	background-color: #FAFBFC;
	border: 1px solid #E6EAEE;
	border-radius: 3px;
	margin-left: 30px;
	margin-top: 9px;
	margin-bottom: 9px;
	display: inline-block;
	img
	{
		width: 70px;
		height: 70px;
		border-radius: 50%;
		float: left;
		margin-left: -31px;
		margin-top: -1px;
	}
	.image-icon
	{
		padding-left: 51px;
		padding-top: 6px;
		a
		{
			border-bottom: 1px solid #E6EAEE;
			padding-bottom: 8px;
			i
			{
				color: #C8D2DF;
				font-size: 16px;
			}
		}
	}
	.image-icon.delete-icon
	{
		padding-top: 15px;
		a
		{
			border-bottom:transparent;
			i
			{
				font-size: 20px;
			}
		}
	}
}
.pencil-icon
{
	background: #1991EB;
	width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    i
    {
    	color: #FFFFFF!important;
    	font-size: 14px;
    	line-height: 26px;
    	margin-left: 1px;
    }
}
.trash-icon
{
	background: #E76877;
	width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    i
    {
    	color: #FFFFFF!important;
    	font-size: 13px;
    	line-height: 27px;
    }
}
@media (max-width:600px)
{
	.privacy-question
	{
		.list-group
		{
			.list-group-item
			{
				.form-group
				{
					label
					{
						padding-top: 0px;
						padding-bottom: 15px;
					}
					margin-bottom: 8px;
					.form-control
					{
						float: none;
					}
				}
			}
		}
	}
}

	.settings-block
	{
		text-transform: capitalize;
		background: #2EA1F8;
		padding: 20px 30px;
		font-size: 18px;
		color: @default-to-color ;
		font-family: @primary-font;
		font-weight: 400;
		letter-spacing: 0.4px;
		.close-modal i 
		{
			color: #70BBFB;
		}
	}
	.settings-list
	{
		background-color: #222C3C;
		margin-left: 0px;
		margin-bottom: 0px;
		display: flex;
		justify-content: space-between;
		li 
		{
			margin-left: 30px;
			margin-right: 30px;
			text-transform: capitalize;
			padding-left: 0px;
			padding-right: 0px;
			border-bottom:3px solid transparent;
			&:first-child 
			{
				margin-left: 10px;
			}
			&:last-child
			{
				margin-right: 7px;
			}
			a 
			{
				color: #848C98;
				padding: 0px;
				border-radius: 0px;
				padding-top: @primary-font-size;
				padding-bottom: 11px;
				font-size: @primary-font-size;
				font-family: @primary-font;
				text-transform: capitalize;
				text-decoration: none;
				font-weight: 400;

				
			}
		}
		li.active 
		{
			border-bottom: 3px solid #2EA1F8;
			a 
			{
				border-radius: 0px;
				color: @default-to-color;
				
			}
		}
	}
	.settings-content
	{
		padding: 0px;
		margin-bottom: 0px;
		border:0px;
	}
	.list-group 
	{
		margin-bottom: 0px;

		.list-group-item 
		{
			padding: 16px 30px;
			cursor: pointer;
			font-size: @primary-font-size;
			color: @primary-content-color;
			border-left: 0px;
			border-right:0px;
			.text-toggle
			{
			    opacity: 0.6;
			    color: #354052;
			}
			.text-selected
			{
			    opacity: 1;
			    color: #354052;
			}	
		}

	}



	.settings-footer
	{
		padding: 12px 30px;
		.btn-send
		{
			background-image: linear-gradient(-180deg, #39B54A 0%, #34AA44 98%);
			border: 1px solid #249533;
			border-radius: 4px;
			color: @default-to-color;
			font-size: @primary-font-size;
			font-family: @primary-font;
		}
	}